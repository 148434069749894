<template>
    <div class="left-content-column">
        <section class="clearfix">
            <article class="post" :data-post-name="post.slug">
                <header class="post-header clearfix">
                    <div class="fb-image visible-mobile" style="min-height: 200px">
                        <img :src="post.facebook_image ? post.facebook_image.small : ''">
                        <div class="post-stats">
                            <div class="user-submission"><i class="user-submission-icon"></i>
                                <span>User submission</span></div>
                            <div class="post-shares-views">
                                <div class="post-views"><i class="post-views-icon"></i> <span>37.3K</span></div>
                            </div>
                        </div>
                        <div class="opacity"></div>
                    </div>

                    <div class="categories-list in-post">
                        <a href="https://www.boredpanda.com/art/digital/">Digital Art</a>,
                        <a href="https://www.boredpanda.com/design/interior/">Interior Design</a>
                        <span class="date-info">5 days ago</span>
                    </div>

                    <h1 class="post-title">
                        {{ post.title }}
                        <span v-if="post.interview_with"
                              class="title-interview">Interview With {{ ucFirst(post.interview_with) }}</span></h1>
                    <div class="post-info-block clearfix visible-desktop">
                        <div class="left post-info">
                            <p class="views"><i>
                                <svg class="svg-icon icon-open-list-views" role="img" height="12" width="18"
                                     viewBox="0 0 18 12">
                                    <path d="M9,4C7.9,4,6.9,5,6.9,6c0,1,1,2,2.1,2c1.1,0,2.1-1,2.1-2C9.5,6,9,5.5,9,4z M13.8,6
c0-2.3-2.3-4.5-4.8-4.5C6.6,1.5,4.2,3.7,4.2,6c0,2.3,2.3,4.5,4.8,4.5C11.4,10.5,13.8,8.3,13.8,6z M18,6c0,0-3.6,6-9,6
c-5.4,0-9-6-9-6s3.6-6,9-6C14.4,0,18,6,18,6z"></path>
                                </svg>
                            </i>37K<span>views</span></p>
                        </div>
                        <div class="right facebook-block-height">
<!--                            <div class="fb-like fb_iframe_widget" data-href="https://www.facebook.com/boredpanda"-->
<!--                                 data-layout="button_count" data-action="like" data-show-faces="false"-->
<!--                                 data-share="false" data-page-like-position="home_page"-->
<!--                                 data-page-like-category="post_like" fb-xfbml-state="rendered"-->
<!--                                 fb-iframe-plugin-query="action=like&amp;app_id=469101399768819&amp;container_width=0&amp;href=https%3A%2F%2Fwww.facebook.com%2Fboredpanda&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;share=false&amp;show_faces=false">-->
<!--                                <span style="vertical-align: bottom; width: 90px; height: 28px;"><iframe-->
<!--                                    name="f3f416236d29d9" width="1000px" height="1000px"-->
<!--                                    data-testid="fb:like Facebook Social Plugin" title="fb:like Facebook Social Plugin"-->
<!--                                    frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no"-->
<!--                                    allow="encrypted-media"-->
<!--                                    src="https://www.facebook.com/v10.0/plugins/like.php?action=like&amp;app_id=469101399768819&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1e40870eba26ec%26domain%3Dwww.boredpanda.com%26origin%3Dhttps%253A%252F%252Fwww.boredpanda.com%252Ff35ab0d60a1be6%26relation%3Dparent.parent&amp;container_width=0&amp;href=https%3A%2F%2Fwww.facebook.com%2Fboredpanda&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;share=false&amp;show_faces=false"-->
<!--                                    style="border: none; visibility: visible; width: 90px; height: 28px;"-->
<!--                                    class=""></iframe></span></div>-->
                        </div>
                    </div>
                    <div class="post-info-block mobile-version clearfix">
                        <div class="left post-info post-authors"><img loading="lazy" name="hidreley-btu"
                                                                      src="https://static.boredpanda.com/blog/wp-content/uploads/2021/01/nwdn_file_temp_1609982320828__30.jpg"><span
                            class="post-author-link"><a href="https://www.boredpanda.com/author/hidreley-btu"
                                                        title="Posts by Hidrėlėy" class="author">Hidrėlėy</a> <br> <span
                            class="member-type community">Pro member</span> </span>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </header>

                <div class="entry clearfix">
                    <div class="post-content">
                        <div class="single-top-ad post-ads">
                            <div class="dfp-ad">
                            </div>
                        </div>
                        <post-content v-for="(content, index) in post.content"
                                      :key="index"
                                      :content="content"
                        ></post-content>
                    </div>
                </div>

            </article>
        </section>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'single-post',
    components: {
        PostContent: defineAsyncComponent(
            () => import(/* webpackPreload: 0 */'@/components/PostContent')
        ),
    },
    props: {
        post: Object,
    },
    methods: {
        ucFirst(str) {
            return str.substr(0, 1).toUpperCase() + str.substr(1);
        }
    }
}
</script>

<style scoped lang="scss">
.left-content-column {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3.125em;

    .post {
        width: 100%;
        display: block;
        padding: 0;
        background: #fff;
        margin-bottom: 1.688em;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        -webkit-box-shadow: compact(0 2px 0 #ccc, false, false, false, false, false, false, false, false, false);
        -moz-box-shadow: compact(0 2px 0 #ccc, false, false, false, false, false, false, false, false, false);
        box-shadow: compact(0 2px 0 #ccc, false, false, false, false, false, false, false, false, false);

        .categories-list {
            padding: 9px 25px;
            max-width: 100%;
            text-transform: uppercase;
            font-size: 13px;

            a {
                color: #d74b1f;
            }

            &.in-post {
                padding: 25px 30px 0 30px;
                @media (min-width: 769px) {
                    padding: 35px 45px 0;
                }

                @media (max-width: 768px) {
                    padding-left: 25px;
                }

                @media (max-width: 480px) {
                    padding: 15px 14px;
                }

                a {
                    display: inline;
                }
            }

            .date-info {
                color: #949494;
                padding-left: 15px;
            }
        }

        .post-title {
            background: 0 0;
            font-family: Merriweather, serif;
            font-weight: 700;
            font-size: 2.3em;
            line-height: 1.2;
            padding: 12px 31px 5px 31px;
            word-wrap: break-word;
            overflow-wrap: break-word;
            margin: 0;

            @media (min-width: 769px) {
                margin-left: .32em;
                margin-right: .32em;
                font-size: 2.5em;
            }

            .title-interview {
                display: inline;
                font-size: .55em;
                color: #d74b1f;
                vertical-align: middle;
                white-space: nowrap;
            }
        }

        .entry {
            position: relative;
            padding: 0 31px 4px 29px;
            color: #949494;
            font-weight: 500;
            line-height: 1.7;
            clear: both;
            text-align: justify;
            @media(min-width: 769px) {
                padding: 0 45px 0 45px;
            }
            @media(max-width: 768px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .post-header {
        position: relative;
        padding: 0;
        @media(max-width: 480px) {
            .opacity {
                display: block;
                position: absolute;
                width: 100%;
                opacity: 0.8;
                height: 35%;
                bottom: 2px;
                z-index: 2;
                background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
                background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#8c000000', GradientType=0);
            }
            .post-stats {
                display: block;
                position: absolute;
                bottom: 10px;
                width: 100%;
                z-index: 3;

                .user-submission {
                    float: left;
                    margin-left: 10px;

                    .user-submission-icon {
                        @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                            background: url(/images/user-submission-white-icon@2x.png) no-repeat;
                            background-size: 14px 16px;
                        }
                        background: url(/images/user-submission-white-icon.png) no-repeat;
                        background-size: 14px 16px;
                        vertical-align: middle;
                        display: inline-block;
                        width: 14px;
                        height: 16px;
                    }
                }

                .post-shares-views {
                    float: right;

                    .post-shares {
                        float: left;
                        margin-right: 20px;

                        .post-shares-icon {
                            @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                background: url(/images/total-shares-icon@2x.png) no-repeat;
                                background-size: 12px 12px;
                            }
                            background: url(/images/total-shares-icon.png) no-repeat;
                            background-size: 12px 12px;
                            vertical-align: middle;
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .post-views {
                        float: left;
                        margin-right: 20px;

                        .post-views-icon {
                            @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                background: url(/images/total-views-icon@2x.png) no-repeat;
                                background-size: 17px 12px;
                            }
                            background: url(/images/total-views-icon.png) no-repeat;
                            background-size: 17px 12px;
                            vertical-align: middle;
                            display: inline-block;
                            width: 17px;
                            height: 12px;
                        }
                    }
                }

                span {
                    color: #fff;
                    font-size: 12px;
                    padding-left: 7px;
                    vertical-align: middle;
                }
            }
            .post-title {
                font-size: 18px;
                line-height: 1.5;
                padding: 15px 12px 7px;

                &.desktop {
                    display: none;
                }
            }
        }
    }

    .fb-image {
        position: relative;

        img {
            width: 100%;
        }
    }
}

.post-info-block {
    margin: 0 0 5px;
    @media (min-width: 769px) {
        padding: 0 45px 0 45px;
    }

    a {
        display: inline-block;
    }

    .left {
        &.post-info {
            font-size: 14px;
            color: #6a6a6a;
            font-weight: 700;

            p {
                float: left;
                line-height: 20px;
                margin: 0 20px 0 0;

                span {
                    font-weight: 400;
                    color: #a0a0a0;
                    margin-left: 2px;
                }
            }

            i {
                color: #a5a5a5;
                padding-right: 5px;
                display: inline-block;

                .icon-open-list-views {
                    margin-top: 2px;
                }
            }

            img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                float: left;
                margin-right: 5px;
            }

            &.post-authors {
                .post-author-link {
                    line-height: 17px;
                    font-weight: 500;
                }
            }
        }
    }

    .right {
        float: right;
        width: 45%;
        text-align: right;

        &.facebook-block-height {
            max-height: 20px;
        }

        div {
            float: right;
        }
    }

    &.mobile-version {
        @media(max-width: 480px) {
            display: block;
            padding: 0 12px 13px 12px;

            .left {
                &.post-info {
                    border-top: 1px solid #e9e9e9;
                    border-bottom: 1px solid #e9e9e9;
                    border-left: none;
                    border-right: none;
                    width: 100%;
                    padding: 10px 0;

                    &.post-authors {
                        margin-right: 4px;

                        .post-author-link {
                            padding-left: 10px;
                        }
                    }

                    span {
                        margin-top: 3px;
                        display: block;
                        float: left;

                        a {
                            padding-right: 10px;
                            color: #7d7d7d;
                            font-size: 14px;
                        }

                        .member-type {
                            margin-top: 2px;
                            font-size: 12px;

                            &.community {
                                color: #d74b1f;
                                font-weight: bold;
                                text-transform: uppercase;
                            }

                            &.staff {
                                color: #7d7d7d;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
            .post-authors {
                display: inline-block;
            }
        }
    }
}
</style>
